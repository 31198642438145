<template>
  <BaseButton keyTextButtonTranslate="login" :handlerClick="goToLogin" />
</template>
<script>
import { authRoutesName } from "@/router/names";

export default {
  name: "LoginBtn",
  components: {
    BaseButton: () => import("@/components/shared/BaseButtons/Index"),
  },
  methods: {
    goToLogin() {
      this.$router.push({
        name: authRoutesName.LOGIN.name,
        params: { fromRoute: { name: this.$route.name } },
      });
    },
  },
};
</script>
